import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import '../App.css';
import helmetGreenBay from '../assets/helmet-greenbay.png';
import helmetSanFran from '../assets/helmet-sanfran.png';
import helmetTenessee from '../assets/helmet-tenessee.png';
import helmetCinncinati from '../assets/helmet-cinncinati.png';
import helmetBrowns from '../assets/helmet-browns.png';
import helmetDetroit from '../assets/helmet-detroit.png';
import helmetJacksonville from '../assets/helmet-jasksonville.png';
import helmetRams from '../assets/helmet-rams.png';
import helmetMinnesota from '../assets/helmet-minnesota.png';
import helmetBears from '../assets/helmet-bears.png';
import helmetBucs from '../assets/helmet-bucs.png';
import helmetBuffalo from '../assets/helmet-buffalo.png';
import helmetCards from '../assets/helmet-cards.png';
import helmetChargers from '../assets/helmet-chargers.png';
import helmetDallas from '../assets/helmet-dallas.png';
import helmetPhilly from '../assets/helmet-philly.png';
import helmetRaiders from '../assets/helmet-raiders.png';
import helmetSeahawks from '../assets/helmet-seahawks.png';
import helmetTexans from '../assets/helmet-texans.png';
import helmetColts from '../assets/helmet-colts.png';
import helmetDenver from '../assets/helmet-denver.png';
import helmetFalcons from '../assets/helmet-falcons.png';
import helmetGiants from '../assets/helmet-giants.png';
import helmetJets from '../assets/helmet-jets.png';
import helmetKC from '../assets/helmet-kc.png';
import helmetMiami from '../assets/helmet-miami.png';
import helmetPanthers from '../assets/helmet-panthers.png';
import helmetPats from '../assets/helmet-pats.png';
import helmetRavens from '../assets/helmet-ravens.png';
import helmetSteelers from '../assets/helmet-steelers.png';
import helmetSaints from '../assets/helmet-saints.png';
import helmetWashington from '../assets/helmet-washington.png';

function Seasons(){

    const [picks, setPicks] = useState([])
    const[dataYear,setDataYear] = useState(2024)
    const[searchYear,setSearchYear] = useState(2024)
    const [weekList, setWeekList] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22])



    useEffect(() => {
        async function getFootball() {
             let myInit = {
                 body:{
                     "Action": "getBets",
                     "year": Number(dataYear)
                 }
             }
             const apiData = await API.post ('buckluckyapi1', '/picks3', myInit)
             console.log("RESPONSE",apiData)
             console.log("YESR",dataYear)
             setPicks(apiData)
        }
        getFootball()
    },[dataYear])

    let yearTotalMark = 0
    let yearTotalBill = 0

    return (
    <div>
        <h2>{dataYear} Season</h2>
        <button className="butt1" type="button" onClick={() => setDataYear(searchYear)} >Get Year</button>
        <input type="text" value={searchYear} onChange={event => setSearchYear(event.target.value)} />          
              {weekList.map( gameWeek => {
                  let weekSumMark = 0
                  let weekSumBill = 0
                  let rowStyle = 'markRow'
                    return(
                        <div className="weekcontainer">                      
                        <table className="grid2">
                        <tbody> 
                            <tr><th>Week-{gameWeek}</th><th>Player</th><th colSpan={2}>Picked Team</th><th>Line</th><th colSpan={2}>Opposing Team</th><th>Final Score</th><th>Outcome</th><th>Wager</th></tr>
                            {picks.filter((item) => item.season_week === gameWeek).map( pick => {
                                let winLose = "TIE"
                                let playerName = ''
                                if(Number(pick.score_picked_team) + Number(pick.line_picked_team) >  Number(pick.score_opposing_team)) {
                                    winLose = "WON"; 
                                } else if(Number(pick.score_picked_team) + Number(pick.line_picked_team) === Number(pick.score_opposing_team)) {
                                    winLose = "TIE";
                                }else{
                                    winLose = "LOST";
                                }
                                if(pick.pick_user_id === 1){
                                    playerName = 'Mark'
                                    rowStyle = 'markRow'
                                    if(winLose === "WON"){
                                        weekSumMark = weekSumMark + pick.wager
                                        weekSumBill = weekSumBill - pick.wager
                                        yearTotalMark = yearTotalMark + pick.wager
                                        yearTotalBill =yearTotalBill - pick.wager
                                    }
                                    if(winLose === "LOST"){
                                        weekSumMark = weekSumMark - pick.wager
                                        weekSumBill = weekSumBill + pick.wager
                                        yearTotalMark = yearTotalMark - pick.wager
                                        yearTotalBill = yearTotalBill + pick.wager
                                    }
                                }else if(pick.pick_user_id === 2){
                                    playerName = 'Bill'
                                    rowStyle = 'billRow'
                                    if(winLose === "WON"){
                                        weekSumMark = weekSumMark - pick.wager
                                        weekSumBill = weekSumBill + pick.wager
                                        yearTotalMark = yearTotalMark - pick.wager
                                        yearTotalBill = yearTotalBill + pick.wager
                                    }
                                    if(winLose === "LOST"){
                                        weekSumMark = weekSumMark + pick.wager
                                        weekSumBill = weekSumBill - pick.wager
                                        yearTotalMark = yearTotalMark + pick.wager
                                        yearTotalBill = yearTotalBill - pick.wager
                                    }
                                }else
                                    playerName = ''
   
                                return(
                                    <tr id={pick.sk} key={pick.sk} className={rowStyle}>
                                        <td></td>
                                        <td>{playerName}</td>
                                        <td>{pick.picked_team_name}</td>
                                        <td>
                                            {pick.picked_team_name === "GreenBayPackers" ? <img src={helmetGreenBay} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "SanFrancisco49ers" ? <img src={helmetSanFran} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "CincinnatiBengals" ? <img src={helmetCinncinati} alt="Logo" className="helmet" /> : null } 
                                            {pick.picked_team_name === "ClevelandBrowns" ? <img src={helmetBrowns} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "DetroitLions" ? <img src={helmetDetroit} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "JacksonvilleJaguars" ? <img src={helmetJacksonville} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "MinnesottaVikings" ? <img src={helmetMinnesota} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "MinnesotaVikings" ? <img src={helmetMinnesota} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "TennesseeTitans" ? <img src={helmetTenessee} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "LosAngelesRams" ? <img src={helmetRams} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "SaintLouisRams" ? <img src={helmetRams} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "ChicagoBears" ? <img src={helmetBears} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "BuffaloBills" ? <img src={helmetBuffalo} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "ArizonaCardinals" ? <img src={helmetCards} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "SanDiegoChargers" ? <img src={helmetChargers} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "DallasCowboys" ? <img src={helmetDallas} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "PhiladelphiaEagles" ? <img src={helmetPhilly} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "OaklandRaiders" ? <img src={helmetRaiders} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "SeattleSeahawks" ? <img src={helmetSeahawks} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "HoustonTexans" ? <img src={helmetTexans} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "IndianapolisColts" ? <img src={helmetColts} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "DenverBroncos" ? <img src={helmetDenver} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "AtlantaFalcons" ? <img src={helmetFalcons} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "NewYorkGiants" ? <img src={helmetGiants} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "NewYorkJets" ? <img src={helmetJets} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "KansasCityChiefs" ? <img src={helmetKC} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "MiamiDolphins" ? <img src={helmetMiami} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "CarolinaPanthers" ? <img src={helmetPanthers} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "NewEnglandPatriots" ? <img src={helmetPats} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "NewOrleansSaints" ? <img src={helmetSaints} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "WashingtonRedskins" ? <img src={helmetWashington} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "TampaBayBuccaneers" ? <img src={helmetBucs} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "BaltimoreRavens" ? <img src={helmetRavens} alt="Logo" className="helmet" /> : null }
                                            {pick.picked_team_name === "PittsburghSteelers" ? <img src={helmetSteelers} alt="Logo" className="helmet" /> : null }
                                       </td>
                                        <td>{pick.line_picked_team}</td>
                                        <td>
                                            {pick.opposing_team_name === "GreenBayPackers" ? <img src={helmetGreenBay} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "SanFrancisco49ers" ? <img src={helmetSanFran} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "CincinnatiBengals" ? <img src={helmetCinncinati} alt="Logo" className="helmet" /> : null } 
                                            {pick.opposing_team_name === "ClevelandBrowns" ? <img src={helmetBrowns} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "DetroitLions" ? <img src={helmetDetroit} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "JacksonvilleJaguars" ? <img src={helmetJacksonville} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "MinnesottaVikings" ? <img src={helmetMinnesota} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "MinnesotaVikings" ? <img src={helmetMinnesota} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "TennesseeTitans" ? <img src={helmetTenessee} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "LosAngelesRams" ? <img src={helmetRams} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "SaintLouisRams" ? <img src={helmetRams} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "ChicagoBears" ? <img src={helmetBears} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "BuffaloBills" ? <img src={helmetBuffalo} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "ArizonaCardinals" ? <img src={helmetCards} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "SanDiegoChargers" ? <img src={helmetChargers} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "DallasCowboys" ? <img src={helmetDallas} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "PhiladelphiaEagles" ? <img src={helmetPhilly} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "OaklandRaiders" ? <img src={helmetRaiders} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "SeattleSeahawks" ? <img src={helmetSeahawks} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "HoustonTexans" ? <img src={helmetTexans} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "IndianapolisColts" ? <img src={helmetColts} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "DenverBroncos" ? <img src={helmetDenver} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "AtlantaFalcons" ? <img src={helmetFalcons} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "NewYorkGiants" ? <img src={helmetGiants} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "NewYorkJets" ? <img src={helmetJets} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "KansasCityChiefs" ? <img src={helmetKC} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "MiamiDolphins" ? <img src={helmetMiami} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "CarolinaPanthers" ? <img src={helmetPanthers} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "NewEnglandPatriots" ? <img src={helmetPats} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "NewOrleansSaints" ? <img src={helmetSaints} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "WashingtonRedskins" ? <img src={helmetWashington} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "TampaBayBuccaneers" ? <img src={helmetBucs} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "BaltimoreRavens" ? <img src={helmetRavens} alt="Logo" className="helmet" /> : null }
                                            {pick.opposing_team_name === "PittsburghSteelers" ? <img src={helmetSteelers} alt="Logo" className="helmet" /> : null }
                                        </td>
                                        <td>    
                                            {pick.opposing_team_name}
                                        </td>
                                        <td>{pick.score_picked_team} - {pick.score_opposing_team}</td>
                                        <td>{winLose}</td>
                                        <td>{pick.wager}</td>          
                                    </tr>
                                )
                            })}
                            <tr className="grid3">
                            <th colSpan={5}>Week Totals: Mark: {weekSumMark} Bill: {weekSumBill}</th><th colSpan={5}>Year Totals: Mark: {yearTotalMark} Bill: {yearTotalBill}</th>
                            </tr>
                        </tbody> 
                        </table>
                        </div>
                    )    
                })}

    </div>
    )

}
export default Seasons