import React, { useState, useEffect } from 'react';
import AnnualStats from './AnnualStats';
import '../App.css';

function Home() {

        return( 
        <div className="content">
        <AnnualStats queryYear="2024" />
        <AnnualStats queryYear="2023" />
        <AnnualStats queryYear="2022" />        
        <AnnualStats queryYear="2021" />        
        <AnnualStats queryYear="2020" />
        <AnnualStats queryYear="2019" />
        <AnnualStats queryYear="2018" />
        <AnnualStats queryYear="2017" />
        <AnnualStats queryYear="2016" />
        <AnnualStats queryYear="2015" />
        <AnnualStats queryYear="2014" />
        <AnnualStats queryYear="2013" />
        <AnnualStats queryYear="2012" />
        <AnnualStats queryYear="2011" />

         <div>
                 <h2>Before Computers:</h2>
                 <p>2010 - Bill $120</p>
                 <p>2009 - Bill $370</p>
                 <p>2008 - Mark $550</p>
                 <p>2007 - Mark $260</p>
         </div>

        </div>
        )
}

export default Home
