import React from "react";
import MyNavBar from './MyNavBar';
import bucklucky from '../assets/bucklucky4.png';

function Header() {
    return (
        <div>
            <div className="divLeft">
                <img src={bucklucky} alt="Logo" className="logo" />
            </div>
            <div className="divHeader1">
                BuckLucky.com
            </div>
            <MyNavBar />
            <div className="divClear"></div>
            
        </div>
    )
}

export default Header