import React from 'react';
import { HashRouter as Router, Link,} from 'react-router-dom';

class MyNavBar extends React.Component {
  render() {
    return (
      <div  className="navRight">
      <Router>
        <div>
          <nav>
            <span className="link1"><Link to="/components/Home">Home</Link></span>
            <span className="link1"><Link to="/components/Seasons">Seasons</Link></span>
            <span className="link1"><Link to="/components/Goat">GOAT</Link></span>
            <span className="link1"><Link to="/components/AddWager">AddWager</Link></span>
          </nav>
        </div>
      </Router>
      </div>
    );
  }
}

export default MyNavBar;