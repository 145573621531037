import React, { useState, useEffect } from 'react';
import AllTimeStats from './AllTimeStats';
import { API } from 'aws-amplify'
import '../App.css';

function Goat() {

        return( 
        <div className="content">
        <AllTimeStats />

         <div>
                 <h2>Before Computers:</h2>
                 <p className="note1">(These dollar figures are reflected in the LifeTime Stats but there is no PickWinner/Loser/Tie data from these years.)</p>
                 <p>2010 - Bill $120</p>
                 <p>2009 - Bill $370</p>
                 <p>2008 - Mark $550</p>
                 <p>2007 - Mark $260</p>
         </div>

        </div>
        )
}

export default Goat
